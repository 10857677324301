/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Paulist = {
    // All pages
    'common': {
      init: function() {
        if( $('div.conversations-feed').length ){
          Interact.convo_slider.init();
        }
        if( $('div.locations-feed').length ){
          Interact.locations_accordion.init();
        }
        if( $('div.row.iso-grid').length ){
          Interact.convo_grid_iso.init();
        }
        if( $('header.sticky-header').length ){
          Interact.header_ctas.start();
        }
        if( $('footer#sticky-footer-ctas').length ){
          Interact.footer_ctas.start();
        }
        if( $('div.youtube-replace').length ){
          Interact.youtube_iframe_api.init();
        }
        if( $('div.audio').length ){
          Interact.rock_the_casbah.init();
        }
        
        $("ul#menu-primary-navigation").sub_navs();
        Interact.mobile_nav();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
				if( $('div.home-banner').find('div.slide').length ){
					Interact.home_banner_slider.init();
				}
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    // Single Location Page
    'single_location': {
      init: function() {
        map = null;
        
        gMaps.new_map( $('div.location-map-wrapper') );
      }
    },
    //Locations Archive Page
    'post_type_archive_location': {
      init: function() {
        Interact.locations_grid_iso.init();
      }
    },
    'tax_location_type': {
      init: function() {
        Interact.locations_grid_iso.init();
      }
    }
  };
  
  var Interact = {
    //Mobile Nav
    mobile_nav: function(){
      $("button#mobile-nav-toggle").bind('click', function(){
        $("nav.nav-primary").animate({
          right: "0px"
        },{
          duration: 500,
          easing: "easeInSine"
        });
      });
      $("div#close-nav").bind('click', function(){
        $("nav.nav-primary").animate({
          right: "-275px"
        },{
          duration: 500,
          easing: "easeInSine"
        });
      });
    },
    //Home Banner Slider
    'home_banner_slider': {
      init: function()
      {
        var slider = $('div.home-banner').find('div.slides');
  
        slider.slick({
					autoplay				: true,
          autoplaySpeed   : 4500,
          infinite        : false,
          slidesToShow    : 1,
          slidesToScroll  : 1,
          dots            : false,
          speed           : 300,
          adaptiveHeight  : true,
          prevArrow       : false,
          nextArrow       : false
        });
        $('div.home-banner').css('visibility', 'visible');
        slider.on( 'init', function( slick ){
          
        });
      }
    },
		//Conversation Slider
    'convo_slider': {
      init: function()
      {
        var slider = $('div.conversation-carousel').find('div.row.conversations');
  
        slider.slick({
          infinite        : false,
          slidesToShow    : 3,
          slidesToScroll  : 3,
          dots            : false,
          speed           : 300,
          adaptiveHeight  : true,
          prevArrow       : 'a.prev-arrow',
          nextArrow       : 'a.next-arrow',
          responsive      : [
            {
              breakpoint  : 992,
              settings    : {
                slidesToShow  : 2,
                slidesToScroll: 2,
                adaptiveHeight: true
              }
            },
            {
              breakpoint  : 720,
              settings    : {
                slidesToShow  : 1,
                slidesToScroll: 1,
                adaptiveHeight: false
              }
            }
          ]
        });
      }
    },
    //Conversation Grid Isotope
    'convo_grid_iso': {
      init: function()
      {
        // init Isotope
        var $iso_grid = $('div.row.iso-grid').isotope({
          itemSelector    : '.conversation',
          isFitWidth      : true,
          layoutMode      : 'fitRows'
        });
        
        var imgs  = $iso_grid.find('img');
        var count = imgs.length;

        imgs.load(function() {
            count--;
            $iso_grid.isotope('layout');
        }).filter(function() { return this.complete; }).load();

        // filter items on button click
        $('ul#conversation-filters').on( 'click', 'a', function() {
          var filterValue = $(this).attr('data-filter');
          $iso_grid.isotope({ filter: filterValue });
        });
      }
    },
    //Conversation Grid Isotope
    'locations_grid_iso': {
      init: function()
      {
        // init Isotope
        var $iso_grid = $('div.location-iso-grid').isotope({
          itemSelector    : '.location',
          isFitWidth      : true,
          layoutMode      : 'fitRows'
        });
        
        var imgs  = $iso_grid.find('img');
        var count = imgs.length;

        imgs.load(function() {
            count--;
            $iso_grid.isotope('layout');
        }).filter(function() { return this.complete; }).load();

        // filter items on button click
        $('ul#location-filters').on( 'click', 'a', function() {
          
          $('ul#location-filters > li > a').removeClass('active');
          $(this).toggleClass('active');
          
          var filterValue = $(this).attr('data-filter');
          $iso_grid.isotope({ filter: filterValue });
        });
        
        var _init = UTIL.getQueryVar('loc');
        
        if( !_init ) {
          return;
        }
        
        _init = UTIL.urldecode(_init);
        _init = UTIL.str_replace(' ', '-', _init);
        _init = UTIL.str_replace('.', '', _init);
        _init = UTIL.strtolower(_init);
        
        var _find = 'a.filter-'+_init;
        
        $('ul#location-filters').find(_find).toggleClass('active');
        
        _init = "." + _init;
        
        $iso_grid.isotope({ filter: _init });
      }
    },
    //Locations Accordion
    'locations_accordion': {
      init: function()
      {
        var zac,
            locations_slider,
            isAccordion = false,
            isSlider    = false,
            make_slider = function(){
              $('ul#locations').slick({
                slide           : "li",
                autoplay        : true,
                autoplaySpeed   : 4500,
                infinite        : false,
                slidesToShow    : 1,
                slidesToScroll  : 1,
                dots            : false,
                speed           : 300,
                adaptiveHeight  : false,
                prevArrow       : false,
                nextArrow       : false,
                centerMode      : false
              });
              isSlider = true;
            };
        
        make_slider();
        
        $('select.locations-selector ').on('change', function(e){
          var _url = $(this).find('option:selected').data('targ');
          window.location.href = _url;
        });
        $('.locations-selector').selectpicker();
        
        enquire.register("screen and (min-width: 992px)",{
          match: function(){
            if( isSlider ){
              $('ul#locations').slick('unslick');
              isSlider = false;
            }
            
            $("ul#locations").zAccordion({ 
              slideClass        : 'fold',
              tabWidth          : "3%",
              width             : "100%",
              height            : "415px",
              speed             : 300,
              buildComplete     : function(){
                isAccordion = true;
              },
              animationStart    : function( i ) {
                $("ul#locations > li").removeClass('active');
              },
              animationComplete : function( i ) {
                var next_index = $(i).index();
                var _activate = $("ul#locations > li").get(next_index);
                $(_activate).addClass('active');
              }
            });
        
            $('ul.locations-nav > li > a').each(function(i,e){
              $(e).one().bind('click', function(e){
                e.preventDefault();
                $("ul#locations").zAccordion( "trigger", i );
                $("ul#locations > li").removeClass('active');
                var _activate = $("ul#locations > li").get(i);
                $(_activate).addClass('active');
              });
            });
          },//end match
          unmatch: function(){
            
            if( isAccordion ){
              $("ul#locations").zAccordion('destroy', {
                removeStyleAttr: true,
                removeClasses: true,
                destroyComplete: {
                  afterDestroy: function() {
                    console.log('destroying accordion');
                    isAccordion = false;
                    $('ul.locations-nav > li > a').unbind();
                    make_slider();
                  }
                }
              });
            }else{
              make_slider();
            }
          }//end unmatch
        });
      }
    },
    //Sticky Header CTAs
    'header_ctas': {
      start: function() {
        var controller = new ScrollMagic.Controller();

        //For the sticky
        var slide_down = new TweenMax.fromTo('header.sticky-header', .5, {
          top: '-300px'
        },{
          top: '0px'
        });

        var footer_scene = new ScrollMagic.Scene({
          triggerElement: '#site-header',
          triggerHook: 'onLeave',
          offset: 200,
          reverse: true
        })
        .setTween(slide_down)
        .addTo(controller);
        
        $('header.sticky-header').find('div.lose-it > i').bind('click', function(e){
          $('header.sticky-header').remove();
        });
      }
    },
    //Sticky Footer CTAs
    'footer_ctas': {
      start: function() {
        bound = false;
        
        $(window).bind('scroll', function(){
          viewport_height = $(window).height();
          sf_offset       = $('footer#site-footer').offset();
          scroll_top      = $(document).scrollTop();
          footer_offset_top = sf_offset.top - scroll_top - viewport_height;
          // console.log('footer offset top = '+footer_offset_top);
          
          if( footer_offset_top <= 0 ){
            $('#sticky-footer-wrap').css('position', 'static');
            bound = true;
          }else{
            if( bound ){
              $('#sticky-footer-wrap').css('position', 'fixed');
              bound = false;
            }
          }
        });
        
        var controller = new ScrollMagic.Controller();

        //For the sticky
        var slide_up = new TweenMax.fromTo('#sticky-footer-wrap', .5, {
          bottom: '-200px'
        },{
          bottom: '0px'
        });

        var footer_scene = new ScrollMagic.Scene({
          triggerElement: '#site-header',
          triggerHook: 'onLeave',
          offset: 200,
          reverse: true
        })
        .setTween(slide_up)
        .addTo(controller);
        
        $('footer#sticky-footer-ctas').find('div.lose-it > i').bind('click', function(e){
          $('footer#sticky-footer-ctas').remove();
        });
      },
      get_footer_height: function() {
        var static_footer_height = $('#site-footer').height();
        var sticky_footer_height = $('#sticky-footer-ctas').height();
        return { stat: static_footer_height, sticky: sticky_footer_height };
      }
    },
    //Youtube iFrame API
    'youtube_iframe_api': {
      init: function( ){
        $('div.youtube-replace').each(function(i,e){
          var $overlay = $(e).parent('div.embed').siblings('div.overlay');
          
          var video_id = $(e).data('videoid');
          var player = $(e).player({
            id          : 'player-'+video_id,
            video       : video_id,
            playerVars    : {
              enablejsapi: 1,
              fs: 1,
              rel: 0,
              showinfo: 0,
              color: 'white'
            },
            events: {
             'play': function(){ 
               if( !Modernizr.touchevents )
                 $overlay.fadeToggle(300); 
             },
             'pause': function(){
               if( !Modernizr.touchevents )
                 $overlay.fadeToggle(300); 
             } 
            }
          });
          
          $overlay.bind('click', function(){
            player.player().trigger('play');
          });
        });
      }
    },
    //Associates Talks
    'rock_the_casbah': {
      init: function(){
        
        var isplaying;
        
        $('div.audio').each(function(i,e){
          var audiosource = $(this).find('div.media').data('audiosource');
          var newsound = new buzz.sound(audiosource, {
            autoplay: false,
            loop: false,
            preload: "metadata"
          });
          
          var _index      = $(this).index();
          var $par        = $(this);
          var $prog       = $(this).find('div.prog');
          var $indicator  = $(this).find('div.indicator');
          var $curtime    = $(this).find('div.current-time');
          var $loc        = $(this).find('div.loc');
          var $howlong    = $(this).find('div.how-long');
          var wasplaying  = false;
          var setter;
          var watcher;
          
          newsound.bind("timeupdate", function(e) {
            var timer = this.getPercent();
            
            $indicator.css('left', timer+'%');
            $loc.css('width', timer+'%');
          });
          
          setter = setInterval(function(){
            if( (newsound.getStateMessage() == "HAVE_ENOUGH_DATA") || (newsound.getStateMessage() == "HAVE_CURRENT_DATA") || (newsound.getStateMessage() == "HAVE_METADATA") ){
              $howlong.html( buzz.toTimer( newsound.getDuration() ) );
              clearInterval(setter);
            }
          }, 1000);
          
          watcher = setInterval(function(){
            $curtime.html( buzz.toTimer( newsound.getTime() ) );
          }, 300);
          
          $indicator.draggable({
            axis        : "x",
            containment : "parent",
            start       : function( event, ui ){
              if( !newsound.isPaused() ){
                newsound.pause();
                wasplaying = true;
              }
            },
            stop        : function( event, ui ){
              // newsound.play();
              var new_position  = (ui.position.left) + $indicator.width();
              var bounds        = $prog.width();
              var percent       = (new_position/bounds) * 100;
              
              newsound.setPercent(percent);
              if( wasplaying ){
                newsound.play();
                wasplaying = false;
              }
              
              watcher = setInterval(function(){
                $curtime.html( buzz.toTimer( newsound.getTime() ) );
              }, 300);
            },
            drag        : function( event, ui ){
              var new_position  = (ui.position.left) + $indicator.width();
              var bounds        = $prog.width();
              var percent       = (new_position/bounds) * 100;
              
              $loc.css('width', percent+'%');
              $curtime.html( buzz.toTimer( buzz.fromPercent(percent, newsound.getDuration()) ) );
              clearInterval(watcher);
            }
          });
          
          $(this).find('div.toggle').data('dex', _index).bind('click', function(){
            if( typeof isplaying !== 'undefined' && isplaying.dex != $(this).data('dex') ){
              isplaying.tog.removeClass('on').addClass('off');
              isplaying.sound.pause();
              isplaying.prog.css('visibility', 'hidden');
              isplaying = undefined;
            }
            
            if( $(this).hasClass('on') ){
              $(this).removeClass('on').addClass('off');
              newsound.pause();
              isplaying = undefined;
            }else{ //Is in "paused" state
              $(this).removeClass('off').addClass('on');
              $prog.css('visibility', 'visible');
              newsound.play();
              isplaying = { tog: $(this), sound: newsound, dex: _index, prog: $prog };
            }
          });
        });
      }
    }
  };
  
  var gMaps = {
    
    new_map: function( $el ) {
      
      // var
      var $markers = $el.find('.marker');
      // vars
      var args = {
        zoom    : 16,
        center    : new google.maps.LatLng(0, 0),
        mapTypeId : google.maps.MapTypeId.ROADMAP
      };
  
      // create map           
      var map = new google.maps.Map( $el[0], args);
  
      // add a markers reference
      map.markers = [];
  
      // add markers
      $markers.each(function(){
        gMaps.add_marker( $(this), map );
      });
  
      // center map
      gMaps.center_map( map );
  
      // return
      return map;
  
    },
    
    add_marker: function( $marker, map ) {
      // var
      var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

      // create marker
      var marker = new google.maps.Marker({
        position  : latlng,
        map     : map
      });

      // add to array
      map.markers.push( marker );

      // if marker contains HTML, add it to an infoWindow
      if( $marker.html() )
      {
        // create info window
        var infowindow = new google.maps.InfoWindow({
          content   : $marker.html()
        });

        // show info window when marker is clicked
        google.maps.event.addListener(marker, 'click', function() {

          infowindow.open( map, marker );

        });
      }

    },
    
    center_map: function( map ) {
      // vars
      var bounds = new google.maps.LatLngBounds();

      // loop through all markers and create bounds
      $.each( map.markers, function( i, marker ){

        var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

        bounds.extend( latlng );

      });

      // only 1 marker?
      if( map.markers.length == 1 )
      {
        // set center of map
          map.setCenter( bounds.getCenter() );
          map.setZoom( 16 );
      }
      else
      {
        // fit to bounds
        map.fitBounds( bounds );
      }

    }
  }

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Paulist;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    },
    getQueryVar: function(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i=0;i<vars.length;i++) {
              var pair = vars[i].split("=");
              if(pair[0] == variable){return pair[1];}
      }
      return(false);
    },
    urldecode: function(str) {
      return decodeURIComponent((str + '')
        .replace(/%(?![\da-f]{2})/gi, function() {
          // PHP tolerates poorly formed escape sequences
          return '%25';
        })
        .replace(/\+/g, '%20'));
    },
    str_replace: function(search, replace, subject, count) {
      var i = 0,
        j = 0,
        temp = '',
        repl = '',
        sl = 0,
        fl = 0,
        f = [].concat(search),
        r = [].concat(replace),
        s = subject,
        ra = Object.prototype.toString.call(r) === '[object Array]',
        sa = Object.prototype.toString.call(s) === '[object Array]';
      s = [].concat(s);

      if (typeof (search) === 'object' && typeof (replace) === 'string') {
        temp = replace;
        replace = new Array();
        for (i = 0; i < search.length; i += 1) {
          replace[i] = temp;
        }
        temp = '';
        r = [].concat(replace);
        ra = Object.prototype.toString.call(r) === '[object Array]';
      }

      if (count) {
        this.window[count] = 0;
      }

      for (i = 0, sl = s.length; i < sl; i++) {
        if (s[i] === '') {
          continue;
        }
        for (j = 0, fl = f.length; j < fl; j++) {
          temp = s[i] + '';
          repl = ra ? (r[j] !== undefined ? r[j] : '') : r[0];
          s[i] = (temp)
            .split(f[j])
            .join(repl);
          if (count) {
            this.window[count] += ((temp.split(f[j]))
              .length - 1);
          }
        }
      }
      return sa ? s : s[0];
    },
    strtolower: function(str) {
      return (str + '')
        .toLowerCase();
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  
  //Old Faithful
  $.fn.sub_navs = function()
  {
    var navs        = $(this).children('li').children('ul'),
      nav_links     = $(this).children('li').children('a'),
      touch_nav       = false,
      hover_products_nav  = false,
      hover_time      = 1000,
      touch_time      = 4000,
      slide_time      = 200,
      _nav_timer,
      nav_check     = function()
      {
        if( !hover_products_nav ){
          navs.stop().slideUp(slide_time);
          nav_links.removeClass('hover');
          nav_links.data('touched', false);
          clearInterval(_nav_timer);
        }
      },
      nav_touch_check   = function()
      {
        navs.stop().slideUp(slide_time);
        nav_links.removeClass('hover');
        nav_links.blur();
        clearInterval(_nav_timer);
        nav_links.data('touched',false);
        touch_nav = false;
      } 

    navs.hide();
    nav_links.hover(function(){
      navs.stop().hide();
      nav_links.removeClass('hover');
      $(this).siblings('ul').stop().slideDown(slide_time);
      $(this).addClass('hover');
      hover_products_nav = true;
      clearInterval(_nav_timer);
    },function(){
      hover_products_nav = false;
      _nav_timer = setInterval(nav_check,hover_time);
    });

    navs.hover(function(){
      hover_products_nav = true;
      clearInterval(_nav_timer);
    },function(){
      hover_products_nav = false;
      _nav_timer = setInterval(nav_check,hover_time);
    });

    if( Modernizr.touchevents ){
      nav_links.unbind();
      navs.unbind();
      nav_links.data('touched',false).click(function(e){
        if( $(this).siblings('ul').length ){
          if( !$(this).data('touched') ){
            e.preventDefault();
            
            navs.stop().hide();
            nav_links.removeClass('hover');
            clearInterval(_nav_timer);
            nav_links.data('touched',false);
            $(this).data('touched', true);
            touch_nav = true;
            $(this).siblings('ul').stop().slideDown(slide_time);
            $(this).addClass('hover');
            _nav_timer = setInterval(nav_touch_check,touch_time);
            return false;
          }else{
            touch_nav = false;
          }
        }
      });
    }
  }

})(jQuery); // Fully reference jQuery after this point.